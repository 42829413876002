/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import AnnouncementsManager from '../../managers/Announcements';

function BrandWidgetTimed({ loyaltyAccountStatus, creatorId, tier }) {
  const [announcements, setAnnouncements] = useState([]);
  const [expiringSoon, setExpiringSoon] = useState(false);

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      setAnnouncements(data.announcements);
    }
  };

  const daysRemaining = (expiryDate) => {
    // const expiryDate = moment('2015-05-14');
    console.log(expiryDate);
    const today = moment();
    return expiryDate.diff(today, 'days');
  };

  const handleStickyWidget = () => {
    const stickyBrand = document.getElementById('brandWidget');
    window.addEventListener('scroll', () => {
      if ((stickyBrand.clientHeight + 200) < window.innerHeight) {
        if (window.scrollY > 730 && window.scrollY < 1400) {
          stickyBrand.classList.add('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        } else if (window.scrollY > 1400) {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.add('-psuedo-fixed');
        } else {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        }
      } else {
        stickyBrand.classList.remove('-fixed');
        stickyBrand.classList.remove('-psuedo-fixed');
      }
    });
  };

  const calculateExpiringSoon = () => {
    if (daysRemaining(moment(loyaltyAccountStatus.loyaltyAccountDates[0].endDate)) < 15) {
      setExpiringSoon(true);
    }
  };

  useEffect(() => {
    console.log(loyaltyAccountStatus);
    if (loyaltyAccountStatus && loyaltyAccountStatus.isActive && loyaltyAccountStatus.loyaltyAccountDates.length > 0) {
      calculateExpiringSoon();
    }
  }, [loyaltyAccountStatus]);

  useEffect(() => {
    getAnnouncements();
    handleStickyWidget();
  }, []);

  return (
    <div id="brandWidget" className="brand-widget">
      <div className="brand-widget__logo">
        {process.env.LOGO_INVERSE
          ? <img src={process.env.LOGO_INVERSE} alt={process.env.SITE_NAME} />
          : <img src={process.env.NAV_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />}
      </div>
      {tier
      && (
      <div className="brand-widget__tier-info">
        Your membership:
        <br />
        <div className="brand-widget__tier-title">{tier ? tier.variationName : 'Unknown'}</div>
        {/* Enjoy the perks of your tier */}
      </div>
      )}
      <div className="brand-widget__description">Thank you for being a part of our program, and we look forward to cooking up some delicious meals and memories together!</div>
      {loyaltyAccountStatus && loyaltyAccountStatus.isActive && loyaltyAccountStatus.loyaltyAccountDates.length > 0
          && (
          <>
            <div className="brand-widget__membership-dates">
              Your membership expires on
              {' '}
              {moment(loyaltyAccountStatus.loyaltyAccountDates[0].endDate).format('MMM DD, YYYY')}
            </div>
            {expiringSoon
            && (
            <div className="brand-widget__membership-expiring-soon">
              You have
              {' '}
              {daysRemaining(moment(loyaltyAccountStatus.loyaltyAccountDates[0].endDate))}
              {' '}
              days left with your membership, renew it today!
            </div>
            )}
          </>
          )}
      <a href={process.env.CLIENT_WEBSITE} target="_blank" className="brand-widget__button" rel="noreferrer">Visit our website</a>
      {announcements.length > 0
      && (
      <div className="brand-widget__updates">
        <div className="brand-widget__updates--header">
          Updates from
          {' '}
          {process.env.SITE_NAME}
        </div>

        <div className="brand-widget__updates--container">
          {announcements.map((announcement) => (
            <div
              key={announcement.announcementId}
              className="brand-widget__updates--item">
              <div dangerouslySetInnerHTML={{ __html: announcement.textContent }}></div>
              <div className="brand-widget__updates--date">
                {moment(announcement.dateCreated).format('MMM DD, YYYY')}
              </div>
            </div>
          ))}
        </div>
        
        {/* <Link to="/account/collection" className="brand-widget__button">View more</Link> */}
      </div>
      )}
    </div>
  );
}

export default BrandWidgetTimed;
