import moment from 'moment-mini';
import { map } from 'lodash';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Redemptions {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
  }

  getPointsType = (type) => {
    let pointsText = 'Unkown action';
    const pointsTypeMap = [
      {
        type: 1,
        name: 'Redeemed a voucher.',
      },
      {
        type: 2,
        name: 'Friend referral.',
      },
      {
        type: 3,
        name: 'Made a purchase.',
      },
      {
        type: 4,
        name: 'Import without redemption.',
      },
      {
        type: 5,
        name: 'Import with redemption.',
      },
      {
        type: 6,
        name: 'Shop referral.',
      },
    ];

    pointsTypeMap.forEach((mapType) => {
      if (mapType.type === type) {
        pointsText = mapType.name;
      }
    });

    return pointsText;
  }

  getRedemptions = (membershipId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'Redemption/membership/' + membershipId + '/redemptions', token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getVouchers = (membershipId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'Redemption/membership/' + membershipId + '/vouchers', token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  redeemVoucher = (payload, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'Redemption/redeem', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }
}

export default new Redemptions();
