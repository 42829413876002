import React, { useState, useEffect } from 'react';

const lkaTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(true);

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        <div className="custom-tiers__heading">
          LKA Membership Tiers and Rewards
        </div>
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>In The Kitchen</th>
              <th>3 Month</th>
              <th>6 Month</th>
              <th>12 Month</th>
            </tr>
            <tr>
              <td>Priority enrollment</td>
              <td>Priority enrollment</td>
              <td>Priority enrollment</td>
              <td>Priority enrollment</td>
            </tr>
            <tr>
              <td>Invitations to special events</td>
              <td>Invitations to special events</td>
              <td>Invitations to special events</td>
              <td>Invitations to special events</td>
            </tr>
            <tr>
              <td></td>
              <td>Access to exclusive contests</td>
              <td>Access to exclusive contests</td>
              <td>Access to exclusive contests</td>
            </tr>
            <tr>
              <td></td>
              <td>LKA branded gift</td>
              <td>LKA branded gift</td>
              <td>LKA branded gift</td>
            </tr>
            <tr>
              <td></td>
              <td>(1) Bring a friend pass</td>
              <td>(2) Bring a friend passes</td>
              <td>(4) Bring a friend passes (value: up to $596)</td>
            </tr>
            <tr>
              <td></td>
              <td>10% discount for private cooking classes</td>
              <td>15% discount for private cooking classes</td>
              <td>20% discount for private cooking classes</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Exclusive access to partner discounts</td>
              <td>Exclusive access to partner discounts</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Progressive knife set preferred price $199 (regular $249)</td>
              <td>Progressive knife set (value $249)</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default lkaTiers;
